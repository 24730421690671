import React from 'react'
import Register from "../components/Register/Register.jsx";
const RegisterPage = () => {
  return (
    <div>
      <Register/>
    </div>
  )
}

export default RegisterPage
